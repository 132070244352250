import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import InvoicesCancellationsList from '../../Containers/InvoicesCancellationsList';
import InvoiceCancellationDetail from '../../Containers/InvoiceCancellationDetail';

function InvoicesCancellationsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={InvoiceCancellationDetail} />
      <Route path={`${match.url}`} component={InvoicesCancellationsList} />
    </Switch>
  );
}

InvoicesCancellationsCard.propTypes = {
  firm: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default InvoicesCancellationsCard;